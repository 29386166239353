<script>
import { downloadDailyShareDataXlsx, loadKeiyoFiveMonthShareData } from '@/api/data'
import moment from 'moment'

export default {
  name: 'DailyData',
  data () {
    return {
      showCalendar: false,
      data: {}
    }
  },
  created () {
    this.loadData(moment().format('YYYY-MM'))
    window.showSetting = function (e) {
      console.log(e)
    }
  },
  methods: {
    loadData (m) {
      loadKeiyoFiveMonthShareData({ month: m }).then(res => {
        this.data = res.data
        this.showCalendar = true
      })
    },
    showSetting () {

    },
    onPanelChange (e) {
      this.loadData(e.format('YYYY-MM'))
    },
    getDayData (m) {
      const day = m.format('YYYY-MM-DD')

      return this.data[day] || { }
    },
    downloadXlsxFile (info) {
      const hide = this.$message.loading(this.$t('user.excel.exporting'), 0)
      downloadDailyShareDataXlsx({ id: info.id }).then(res => {
        hide()
        this.saveResultAsExcel(res, `Alcohol_Check_${info.date}.xlsx`)
      })
    },
    headerRender ({ value, type, onChange, onTypeChange }) {
      const start = 0
      const end = 12
      const monthOptions = []

      const current = value.clone()
      const localeData = value.localeData()
      const months = []
      for (let i = 0; i < 12; i++) {
        current.month(i)
        months.push(localeData.monthsShort(current))
      }

      for (let index = start; index < end; index++) {
        monthOptions.push(
          <a-select-option class='month-item' key={`${index}`}>
            {months[index]}
          </a-select-option>
        )
      }
      const month = value.month()

      const year = value.year()
      const options = []
      for (let i = year - 10; i < year + 10; i += 1) {
        options.push(
          <a-select-option key={i} value={i} class='year-item'>
            {i}
          </a-select-option>
        )
      }
      const btnStr = this.$t('user.alc.xlsx.auto.send')
      return (
        <div style={{ padding: '10px' }}>
          <a-row type='flex' justify='space-between'>
            <a-col>
              <a-select
                size='medium'
                dropdownMatchSelectWidth={false}
                class='my-year-select'
                onChange={newYear => {
                  const now = value.clone().year(newYear)
                  onChange(now)
                }}
                value={String(year)}
              >
                {options}
              </a-select>
              <a-select
                size='medium'
                class='margin-left-16'
                dropdownMatchSelectWidth={false}
                value={String(month)}
                onChange={selectedMonth => {
                  const newValue = value.clone()
                  newValue.month(parseInt(selectedMonth, 10))
                  onChange(newValue)
                }}
              >
                {monthOptions}
              </a-select>
            </a-col>
          </a-row>
        </div>
      )
      // return (
      //   <div style={{ padding: '10px' }}>
      //     <a-row type='flex' justify='space-between'>
      //       <a-col>
      //         <a-select
      //           size='medium'
      //           dropdownMatchSelectWidth={false}
      //           class='my-year-select'
      //           onChange={newYear => {
      //             const now = value.clone().year(newYear)
      //             onChange(now)
      //           }}
      //           value={String(year)}
      //         >
      //           {options}
      //         </a-select>
      //         <a-select
      //           size='medium'
      //           class='margin-left-16'
      //           dropdownMatchSelectWidth={false}
      //           value={String(month)}
      //           onChange={selectedMonth => {
      //             const newValue = value.clone()
      //             newValue.month(parseInt(selectedMonth, 10))
      //             onChange(newValue)
      //           }}
      //         >
      //           {monthOptions}
      //         </a-select>
      //       </a-col>
      //       <a-col>
      //         <div>
      //           <a-button icon='setting' onClicks={this.showSetting}>{btnStr}</a-button>
      //         </div>
      //       </a-col>
      //     </a-row>
      //   </div>
      // )
    }
  }
}
</script>

<template>
  <div class="ant-card" style="padding: 8px;">
    <a-calendar @panelChange="onPanelChange" :fullscreen="true" :header-render="headerRender" v-if="showCalendar">
      <div slot="dateCellRender" slot-scope="value" class="events">
        <div v-if=" getDayData(value).date">
          <div style="text-align: center">
            <a-progress
              type="circle"
              :width="60"
              :stroke-color="{
                '0%': '#108ee9',
                '100%': '#87d068',
              }"
              :percent="getDayData(value).percent"
            >
              <template #format="percent">
                <div>{{ getDayData(value).percent }}%</div>
                <div style="color: #000c17"><span style="padding: 0 2px;" :title="$t('user.alc.test.num').replace('{count}',getDayData(value).test_u_num)">{{ getDayData(value).test_u_num }}</span>/<span style="padding: 0 2px;" :title="$t('user.bind.num').replace('{count}',getDayData(value).total_u_num)">{{ getDayData(value).total_u_num }}</span></div>
              </template>
            </a-progress>
          </div>
          <div style="text-align: center" v-if="getDayData(value).download_tag" ><a :title="$t('user.alc.xlsx.download.tip')" @click="downloadXlsxFile(getDayData(value))">ダウンロード</a></div>
        </div>

      </div>
    </a-calendar>
  </div>
</template>

<style scoped lang='less'>
.my-year-select {
  width: 80px;
}
</style>
